.marginHelpers(@counter) when (@counter >= 0) {
  @value: @counter * 1px;

  .marginHelpers((@counter - 4));
  .mr-@{counter} {
    margin-right: @value !important;
  }
  .ml-@{counter} {
    margin-left: @value !important;
  }
  .mb-@{counter} {
    margin-bottom: @value !important;
  }
  .mt-@{counter} {
    margin-top: @value !important;
  }
  .mx-@{counter} {
    margin-left: @value !important;
    margin-right: @value !important;
  }
  .my-@{counter} {
    margin-top: @value !important;
    margin-bottom: @value !important;
  }
}

.paddingHelpers(@counter) when (@counter >= 0) {
  @value: @counter * 1px;

  .paddingHelpers((@counter - 4));
  .pr-@{counter} {
    padding-right: @value !important;
  }
  .pl-@{counter} {
    padding-left: @value !important;
  }
  .pb-@{counter} {
    padding-bottom: @value !important;
  }
  .pt-@{counter} {
    padding-top: @value !important;
  }
  .px-@{counter} {
    padding-left: @value !important;
    padding-right: @value !important;
  }
  .py-@{counter} {
    padding-top: @value !important;
    padding-bottom: @value !important;
  }
}

.marginHelpers(100);
.paddingHelpers(100);

.lowerCase {
  text-transform: lowercase;
}

.upperCase {
  text-transform: uppercase;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-popover {
  @media print {
    display: none !important;
  }
}