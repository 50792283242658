@import '~antd/dist/antd.less';
@import 'Shared/styles/helpers';


.ant-message {
  top: auto;
  bottom: 32px;
}

.riverflex-notification {
  .ant-message-custom-content {
    min-width: 580px;
    display: flex;
    align-items: center;
    span:last-child {
      width: 100%;
      text-align: left;
    }
  }
}

.App {

  h2 {
    line-height: 35px;
  }

  h4 {
    line-height: 23px;
  }

  .ant-typography,
  .ant-select-item-empty {
    letter-spacing: -0.4px;
  }
  div.ant-typography,
  .ant-typography p {
    line-height: @typography-paragraph-line-height;
  }
  
  
  .ant-steps-item-title {
    letter-spacing: -0.4px;
    font-size: 15px;
  }
}

.color-primary {
  color: @primary-color;
}

.ant-picker-dropdown-range {
  .ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, 
  .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-time-panel {
    width: 400px,
  }
}

// antd window elements with overlay have zIndex of 1000
// See list of zIndexes: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less#L372
// Attempt to put 3dparty chatbox below all antd windows (modals/drawers/etc)
#crisp-chatbox {
  z-index: 999;
}

.react-colorful {
  .react-colorful__saturation,
  .react-colorful__last-control {
    border-radius: 0;
  }
}
@font-family: Roboto, '-apple-system', BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;@typography-title-font-weight: 700;@typography-title-margin-bottom: 0;@typography-title-margin-top: 0;@typography-paragraph-line-height: 16px;@input-affix-margin: 8px;@steps-title-line-height: 32px;@layout-body-background: #ffffff;@layout-sider-background-light: #f5f5f5;@menu-collapsed-width: 66px;@input-addon-bg: transparent;@collapse-content-bg: #ffffff;@collapse-header-bg: #FCFCFC;@drawer-body-padding: 24px 24px 8px;@list-item-padding: 24px 24px;@list-item-padding-sm: 16px 24px;@list-item-padding-lg: 8px 16px;